html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
}

.navbar {
  /* padding: 30px 100px; */
  padding: 1rem 0;
}

.popup-image {
  width: 60%;
  display: block;
  margin: 0 auto 20px;
}

.navbar-brand {
  font-family: 'Palanquin';
  color: #fff;
  font-size: 1em;
}

.c-nav:first-child > a {
  margin-left: -45px !important;
}

.pull-xs-right > .nav-item > a {
  margin-right: 0 !important;
}

.c-nav > a {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  margin-right: 15px;
  text-align: center;
}

.bold-nav-item {
  font-weight: 600 !important;
}

.pull-xs-left {
  visibility: hidden;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23253ec6' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.hero {
  background-color: #EFFCFF;
  /* background-image: linear-gradient(-270.18deg,
      rgba(19, 22, 103, 0.8) 10.63%,
      rgba(19, 22, 103, 0.8) -10.63%,
      rgba(50, 53, 147, 0.8) 55.62%,
      transparent 133.73%),
    url('./assets/alternate-background.png'); */
  background-size: cover;
  background-position: 100% 100%;
  width: 100% !important;
  min-height: 100vh;
  padding-bottom: 36px !important;
}

.description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #373744;
  width: 65%;
  line-height: 30px;
  margin: 15px auto 15px 0;
}

.select {
  width: 20%;
  font-size: 1.1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1;
  box-shadow: 50px 20px 100px rgba(169, 169, 169, 0.5);
}

.select > div::-webkit-scrollbar {
  width: 0;
}

.select > div {
  padding: 15px 15px 15px 20px;
  border-radius: 0;
  /* border-right: none; */
}

.select-radius > div {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.booking-button {
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-weight: 600;
  font-size: 1.1rem;
}

.why_homero {
  padding: 10rem 0;
}
.quote {
  padding: 5rem auto 0;
}

.image-right {
  float: right;
  margin-right: 0;
  display: block;
}

.image-left {
  float: left;
  margin-left: 0;
  display: block;
}

.headline-2 {
  margin-top: 20vh;
}
h2.headline-2 {
  font-size: 2rem;
  font-weight: 600;
  width: 80%;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

.headline-2 + br + p {
  width: 80%;
}

p {
  opacity: 0.8;
}

.center {
  text-align: center;
}

.how_it_works {
  text-align: center;
  padding-bottom: 5rem;
}

.border-left {
  border-top-left-radius: 80px !important;
  border-left: 2px solid #d3f7ff !important;
}

.border-left > img {
  border-radius: 8px;
  border-top-left-radius: 80px !important;
}

.border-right {
  border-top-right-radius: 80px !important;
  border-right: 2px solid #d3f7ff !important;
}

.border-right > img {
  border-radius: 8px;
  border-top-right-radius: 80px !important;
}

.how_it_works_card {
  text-align: center;
  border-radius: 8px;
  border: 2px solid #d3f7ff;
  padding-bottom: 15px;
  margin-bottom: 25px;
  width:100%;
}

.how_it_works_card:hover {
  box-shadow: 0 4px 100px rgba(0, 0, 0, 0.2);
}

.how_it_works_card > img {
  width: 100%;
}

.how_it_works_card:hover > .card_header {
  color: #0093ED;
}

.card_header {
  margin-top: 15px;
  font-weight: 600;
}

.card_text {
  font-size: 0.85rem;
  width: 80%;
  margin: 5px auto;
}

.our_features {
  background-color: #d3f7ff;
  text-align: center;
  padding: 3rem 0 6rem;
}

.our_services {
  margin-bottom: 125px;
}

.our_features > .container > .headline-2 {
  margin: 55px auto 75px;
}

.icon_holder {
  background-color: #fff;
  padding: 15px;
  margin: 25px auto;
  width: 40%;
  border-radius: 14px;
  box-shadow: 0 4px 50px #c7e5ec;
}

.icon_holder_h > img {
  width: 100%;
}

.font-weight-light {
  opacity: 0.7;
}

.review-div {
  background: #FFFFFF;
  border: 1px solid #D5DCEE;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.reviews {
  background-color: #E5F2F6;
  width: 100%;
}
.reviews .container {
  padding-top: 4rem !important;
  padding-bottom: 1rem;
}
.reviews .headline-2 {
  margin: 0 auto 1rem;
}
.gap-1 {
  gap: 1rem;
}

.button-group > button {
  border-radius: 50%;
  border: none;
  padding: 10px;
  margin-right: 10px;
}

.button-group > button:last-child {
  background-color: #0093ED;
}

.center-img {
  display: block;
  margin: 15px auto 20px;
}

.center-img + p {
  opacity: 1;
  font-weight: 600;
}

.areas {
  padding: 2rem 0 3rem;
}

.footer {
  padding: 2rem 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.blue {
  color: #0093ED;
  margin-bottom: 25px;
  display: block;
}

.footer-p {
  font-size: 0.8rem;
  line-height: 25px;
}

.footer-p > a {
  text-decoration: none;
  color: #fff;
}

.footer-header {
  font-size: 2rem;
  font-family: 'Palanquin';
  margin-bottom: 0;
}

.blue > strong {
  display: block;
  font-size: 1.05rem;
}

.footer-list {
  float: right;
  list-style: none;
}

.footer-list > li > a {
  font-size: 0.85rem;
  line-height: 30px;
  text-decoration: none;
  color: #fff;
}

.copyrights {
  margin-top: 50px;
  opacity: 0.7;
}

.spinner {
  display: block;
  margin: 45vh auto 0;
}

.copyrights > div > small {
  display: block;
}

.copyrights > div:nth-of-type(2) > small {
  text-align: center;
}

.copyrights > div:last-of-type > small {
  text-align: right;
}

.copyrights > div > small > a {
  color: #fff;
}

.social-list-item > a > i {
  margin-right: 15px;
  font-size: 1.25rem;
}

/* services page */
.easy_123 {
  background-color: #fff;
  padding: 50px 0 10rem;
}

.secondary-tagline {
  margin: 25vh auto 15px;
  font-weight: 600;
  color: #33393d;
  font-size: 3rem;
}

.specials-p {
  width: 90%;
  margin: 5px auto;
}
.secondary-tagline + p {
  margin-bottom: 30px;
}

.secondary-tagline + p,
.description {
  opacity: 1;
}

.secondary-hero-img {
  display: block;
  width: 80%;
  margin: 10vh auto 15vh;
  /* float: right; */
}

.number_holder {
  background: #253ec6;
  display: block;
  color: #fff;
  text-align: center;
  width: 20%;
  padding: 10px 20px;
  margin: 25px auto;
  font-size: 2rem;
  box-shadow: 0 4px 50px #f0f0f0;
  border-radius: 14px;
}

.one {
  background: #0093ED;
  color: #fff;
}

.three {
  background: #d3f7ff;
  color: #0093ED;
}

.number_holder + p {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  font-size: 0.95rem;
}

.bold {
  font-weight: 600;
}

.addons {
  padding: 5rem 0 2rem;
  background-color: #fff;
}

.addons > h2 {
  width: 80%;
  margin: auto;
}

.moving {
  color: #fff;
  padding: 5rem 0;
  background-color: #253EC6;
}

.text-content-left {
  width: 80%;
}

.text-content-left .center {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.specials {
  padding: 5rem 0;
  background-color: #f5f5f5;
}

.faq {
  padding: 5rem 0;
  background-color: #fff;
}

.book {
  padding: 0 0 5rem;
}

.cleaning-types-features {
  background-color: #fff;
  padding: 15px;
  border: 2px solid #0093ED;
  box-shadow: 6px 6px 40px #e7e7e7;
  border-radius: 10px;
}

.cleaning-types-features > div > h4 {
  margin-top: 25px;
}

.feature-list-item {
  font-size: 0.8rem;
  opacity: 1;
  font-weight: normal;
  line-height: 25px;
  list-style: none;
}

ol > .feature-list-item {
  list-style: symbols(1);
}

.mission-hero {
  background-image: url('./assets/mission-hero.png');
  background-size: cover;
  background-position: 20%;
  padding: 25vh 0 5vh;
}

.goals {
  padding: 8rem 0;
}

.made-at-ny {
  color: #fff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  text-align: left !important;
}

.card_text {
  width: 100%;
}

.icon_left {
  margin-top: 50px;
  display: block;
  margin-left: 0 !important;
}

.cleaning_methods_p {
  width: 60%;
  margin: 20px auto;
  line-height: 30px;
}

.img_center {
  display: block;
  margin: 20px auto;
}
.comparison {
  margin: 5rem auto;
}
.comparison-item {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  gap: 0;
  font-family: Poppins, sans-serif;
}
.comparison-body {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.comparison-item:not(:last-of-type) {
  border-bottom: 1px solid #DEDFFC;
}
.comparison-head__homero {
  color: #253EC6;
}
.comparison-head__homero {
  border-top: 2px solid #253ec6;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  width: 70%;
}
.comparison-footer__homero {
  border-bottom: 2px solid #253ec6;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  width: 70%;
}
.comparison-item__title {
  text-align: left !important;
  display: flex !important;
  justify-self: flex-start;
}
.comparison-item__homero, .comparison-head__homero, .comparison-footer__homero {
  padding: 1rem 0;
  border-left: 2px solid #253ec6;
  border-right: 2px solid #253ec6;
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
}
.comparison-item__homero, .comparison-item__others, .comparison-item__independent {
  justify-content: center;
  align-items: center;
  width: 70%;
  text-align: center;
  justify-self: center;
  display: flex;
  margin: 0 auto;
}
.comparison-item__homero {
  z-index: 2;
}

@media screen and (max-width: 600px) {
  .cleaning_methods_p {
    width: 100%;
  }

  .hero {
    /* background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0.7) 0%,
        rgba(2, 0, 36, 0.5) 35%,
        rgba(2, 0, 36, 0.6) 100%
      ),
      url('./assets/mobile-background-2.jpg') no-repeat !important; */
      background: #EFFCFF;
    background-size: cover !important;
    width: 100% !important;
    padding: 0;
    background-position: 70% !important;
    height: 100% !important;
    padding-bottom: 30vh;
  }

  .secondary-tagline {
    font-size: 2rem;
  }

  .secondary-tagline + p {
    margin-bottom: 30px;
  }

  .mobile-hidden {
    display: none;
  }

  .how_it_works_card {
    width: 80%;
    margin: 25px auto;
  }

  .how_it_works_card > img {
    width: 100%;
  }

  .cleaning-types-features {
    background-color: #fff;
    margin: 0 auto;
    width: 97%;
    padding: 15px;
    border: 2px solid #0093ED;
    box-shadow: 6px 6px 40px #e7e7e7;
    border-radius: 10px;
  }

  .why_homero {
    padding: 2rem auto !important;
  }
  .quote {
    padding: 2rem auto !important;
  }

  .description {
    width: 100% !important;
    line-height: 25px;
  }

  .book {
    margin: 50px auto 0;
    width: 80%;
  }

  .book-select {
    display: block !important;
  }

  .select {
    width: 100%;
    margin: 25px auto;
    font-size: 1.1rem;
    box-shadow: 50px 20px 100px rgba(169, 169, 169, 0.5);
  }

  .select > div::-webkit-scrollbar {
    width: 0;
  }

  .select > div {
    padding: 5px;
    border-radius: 5px;
    width: 100%;
  }

  .select-radius > div {
    border-radius: 5px;
  }

  .booking-button {
    display: block !important;
    margin: 25px auto !important;
    width: 100%;
    border-radius: 5px !important;
    font-weight: 600;
    font-size: 1.1rem;
  }

  .made-at-ny {
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-toggler {
    border: none !important;
  }

  .pull-xs-left {
    display: none;
  }

  .pull-xs-right {
    display: block !important;
  }

  #navbarSupportedContent > .container {
    display: block;
    text-align: center;
  }

  .c-nav:first-child > a,
  .c-nav > a {
    margin: 0 auto !important;
  }

  .hero {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .image-right,
  .image-left {
    float: none;
    margin: 10px auto;
  }

  .headline-2 {
    margin-top: 50px;
  }

  .headline-2 + br + p {
    width: 100%;
  }

  .why_homero {
    padding: 5rem 25px 5rem;
  }

  .how_it_works_card {
    width: 80%;
    margin: 25px auto;
  }

  .how_it_works_card > img {
    width: 100%;
  }

  .center-img {
    width: 80%;
  }

  .footer-list {
    text-align: left;
    float: left !important;
  }

  .copyrights > div > small {
    text-align: left !important;
    margin-bottom: 15px;
  }

  .secondary-tagline {
    margin: 25px auto;
  }

  .services-hero {
    padding-bottom: 50px;
  }

  .secondary-hero-img {
    display: none;
  }

  .number_holder {
    width: 30%;
    padding: 20px;
  }

  .text-content-left {
    width: 100%;
  }

  .description {
    width: 80%;
  }

  .icon_holder {
    width: 30%;
  }
}

@media screen and (min-width: 601px) and (max-width: 990px) {
  .select {
    width: 30%;
    margin: 25px auto;
    font-size: 1.1rem;
    box-shadow: 50px 20px 100px rgba(169, 169, 169, 0.5);
  }

  .select > div::-webkit-scrollbar {
    width: 0;
  }

  .select > div {
    padding: 20px;
    border-radius: 0;
    margin-left: 0;
    width: 100%;
  }

  .select-radius > div {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .booking-button {
    margin: 25px auto !important;
    font-weight: 600;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .hero {
    height: 100%;
    padding-bottom: 5vh;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .headline-2 {
    margin-top: 12vh;
  }

  .secondary-hero-img {
    margin: 15vh auto;
    width: 100%;
  }
}

@media screen and (min-width: 2559px) {
  .headline-2 {
    margin-top: 15vh;
  }
}

@media screen and (max-width: 768px) {
  .image-left, .image-right {
    width: 70%;
  }
  .container .row p {
    font-size: 12px;
  }
  .container .row .headline-2 {
    font-size: 22px;
  }
  .container .row .headline-2 h1 {
    font-size: 14px;
  }
  .container .row button {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}
